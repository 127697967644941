jQuery(function ($) {

});

/*
 * 
 * Instanciranje popArt objekta.
 * Unutar objekta se nalaze univerzalne metode i mogucnost selektovanja svih elemenata koji
 * se nalaze u formi.
 * 
 * sve sto treba uraditi je dodati atribut svakom polju data-tip="nesto";
 * 
 * 
 * PRIMER:
 * 
 * <input type="text" data-tip="nesto" name="nesto" class="form-control" >
 * 
 * var pop = new popArt();
 * console.log(pop.nesto)  //  vraca input u koji smo dodali data-tip
 * 
 * pop.nesto.onclick=function(){
 * 	  // izvrsavanje eventa na taj element
 * }
 * 
 * 
 * 
 */

var popArt = function () {
	var array = [];
	var allElements = document.getElementsByTagName('*');

	for (var i = 0; i < allElements.length; i++) {
		if (allElements[i].getAttribute('data-tip') !== null) {
			array.push(allElements[i]);
			this[allElements[i].getAttribute('data-tip')] = allElements[i];
		}
	}
	this.elements = array;
	this.transferPageClone;
	this.tooltip();
};

popArt.prototype = {
    tooltip:function(){
        var allTooltip = $('[tooltiptext]');
        for (var i = 0; i < allTooltip.length; i++) {
            $(allTooltip[i]).addClass('tooltipGlo');
            allTooltip[i].onmouseover=function(){
                if($(this).find('.tooltiptext').is(':visible')){
                    return false;
				}
				
                var div = document.createElement('div');
                div.setAttribute('class','tooltiptext');
                var msg = this.getAttribute('tooltiptext');
                    div.innerHTML = msg;
                if(msg.length > 15 && msg.length < 25){
                    div.style.width = '200px';
                    // div.style.marginLeft = '-100px';
                }else if(msg.length >= 25){
                    div.style.width = '300px';
                    // div.style.marginLeft = '-150px';
				}
				
				if ($(this).offset().left + 150 > $(window).width()) {
					div.setAttribute('class', 'tooltiptext tooltiptext-left');
				} else if ($(this).offset().left - 150 < 0) {
					div.setAttribute('class', 'tooltiptext tooltiptext-right');	
				}
                $(this).append(div);
                $(div).show();
            }
            allTooltip[i].onmouseleave=function(){
                $(this).find('.tooltiptext').hide(200,function(){
                    $(this).remove();
                });
            }
        }
	},
	
    object:function(tip){
         var allElements = document.querySelectorAll('[obj="'+tip+'"]');
         var obj = {};
         for(var i=0;i<allElements.length;i++){
			 var attr = allElements[i].getAttribute('name');
			 var positionSquare = attr.indexOf('[]');
			 
			 if(positionSquare != -1){
				if(!obj[attr]){
					obj[attr] = [];
					obj[attr].push(allElements[i].value);
				}else{
					obj[attr].push(allElements[i].value);
				}
			}else{
				obj[attr] = allElements[i].value;
			}
         }
         return obj;
    },
	/*
	 * 
	 * KLONIRANJE ELEMENATA
	 * 
	 * POZIVANJE FUNKCIJE cloneUn(3,this,5,'nekaClassa')
	 * PARAMETRI FUNKCIJE :
	 * 1. PUTEM INTIDZERA ODREDJUJEMO PARENT KOJI ZELIMO DA KLONIRAMO
	 * 2. VRACAMO BTN OD KOG BROJIMO PARENT
	 * 3. KOLIKO PUTA ZELIMO DA NAPRAVIMO KLON
	 * 4. JEDINSTVENOM KLASOM BROJIMO KOLIKO ELEMENATA IMAMO
	 * 
	 * 
	 * HTML:
	 * NA SAM BUTTON GDE POZIVAMO FUNKCIJU DODELJUJEMO JEDINSTVENU KLASU.
	 * ATTRIBUTOM clone="clearClone" GOVORIMO FUNKCIJI KOJI ELEMENTI ZELIMO DA OCISTIMO PRILIKOM KLONA,
	 * MOZEMO DA BRISEMO TEXT,VREDNOSTI IZ INPUTA,SELECTA,CHECKBOX,RADIO,LABEL,DIV ITD.....
	 * 
	 * 
	 * 
	 * PRIMER :
	 * 
	 * <div class="col-md-6">
			<div class="input-group">
		      <input type="text" name="nesto" emp clone="clearClone" class="form-control" >
		      <span class="input-group-btn">
		        <button class="btn btn-info cloneEleMesto" onclick="pop.cloneUn(3,this,2,'cloneEleMesto')" type="button">+</button>
		      </span>
		    </div>
	   </div>
	 * 
	 * 
	 * 
	 */
	
	cloneUn: function (par, ele, koliko, klasaBtn) {
		var parentEle;
		var randNum = Math.floor(Math.random(10000) * 10000);

		for (var i = 0; i < par; i++) {
			if (i == 0) {
				var parent = ele.parentNode;
				parentEle = parent;
			} else {
				var nextEle = parentEle.parentNode;
				parentEle = nextEle;
			}
		}

		var limit = $(parentEle).parent().find('.' + klasaBtn);
		if (limit.length == koliko) return false;

		var clone = parentEle.cloneNode(true);
		var btn = $(clone).find('.' + klasaBtn);
		this.transferPageClone = clone;
		
		$(btn).text(' - ');
		$(btn).removeClass();
		$(btn).addClass('btn btn-warning ' + klasaBtn);
		$(btn).removeAttr('onclick');
		btn[0].onclick = function () {
			$(clone).remove();
		}

		var allEle = $(clone).find('[clone=clearClone]');

		this.removePromoPricesFromClone(clone);

		for (var i = 0; i < allEle.length; i++) {
			if (allEle[i].tagName == 'INPUT') {
				(allEle[i]).value = '';
				if($(allEle[i]).hasClass('datepicker')) {
					$(allEle[i]).removeClass('hasDatepicker');
					$(allEle[i]).attr('id', $(allEle[i]).attr('id')+'-'+randNum);

					$(allEle[i]).datepicker({
						dateFormat: 'dd.mm.yy.',
						minDate: new Date(),
						onSelect: function(date) {
							var dateTo = $(this).parents('.date-picker-row').find('.datepicker-wrapper .date-to');
							var minDate = $(this).datepicker('getDate');
							$(dateTo).datepicker( "option", "minDate", minDate);
						}
					});
				}
			} else if (allEle[i].tagName == 'SELECT') {
				allEle[i].value = '';
			} else if (allEle[i].tagName == 'LABEL') {
				allEle[i].innerHTML = '';
			} else if ($(allEle[i]).hasClass('glyphicon glyphicon-star')) {
				$(allEle[i]).removeClass();
				$(allEle[i]).addClass('glyphicon glyphicon-star-empty');
			}
		}
		var turboParent = $(parentEle).parent();
		
		$(clone).appendTo(turboParent);
		this.handleTransferCloneDayPeriod(clone);
		//this.handleTransferCloneHours(clone);
		// $(parentEle).appendTo(clone);
		// check if it has clone=cloneValue
		// set value to 00:00 instead of deleting values
	},
	cloneTransfer: function (par, ele, koliko, klasaBtn, counter) {
		var parentEle;
		var randNum = Math.floor(Math.random(10000) * 10000);

		for (var i = 0; i < par; i++) {
			if (i == 0) {
				var parent = ele.parentNode;
				parentEle = parent;
			} else {
				var nextEle = parentEle.parentNode;
				parentEle = nextEle;
			}
		}

		var limit = $(parentEle).parent().find('.' + klasaBtn);
		if (limit.length == koliko) return false;

		var clone = parentEle.cloneNode(true);
		var btn = $(clone).find('.' + klasaBtn);
		this.transferPageClone = clone;
		
		$(btn).text(' - ');
		$(btn).removeClass();
		$(btn).addClass('btn btn-warning ' + klasaBtn);
		$(btn).removeAttr('onclick');
		btn[0].onclick = function () {
			$(clone).remove();
		}

		var allEle = $(clone).find('[clone=clearClone]');

		this.removePromoPricesFromClone(clone);

		for (var i = 0; i < allEle.length; i++) {
			if (allEle[i].tagName == 'INPUT' ||	 allEle[i].tagName == 'SELECT') {
				
				if($(allEle[i]).attr('time') == null){
					allEle[i].value = '';
				}

				if($(allEle[i]).hasClass('datepicker')) {
					$(allEle[i]).removeClass('hasDatepicker');
					$(allEle[i]).attr('id', $(allEle[i]).attr('id')+'-'+randNum);

					$(allEle[i]).datepicker({
						dateFormat: 'dd.mm.yy.',
						minDate: new Date()
					});
				}

				if($(allEle[i]).hasClass('array')){
					var name = $(allEle[i]).attr('name');
					var position = name.indexOf('[');
					var newName = name.slice(0, position) + '[' + randNum + '][]';
					$(allEle[i]).attr('name', newName);
				}
				
				if($(allEle[i]).hasClass('identifier')){
					$(allEle[i]).val(randNum);
				}
							
			} else if (allEle[i].tagName == 'LABEL') {
				allEle[i].innerHTML = '';
			} else if ($(allEle[i]).hasClass('glyphicon glyphicon-star')) {
				$(allEle[i]).removeClass();
				$(allEle[i]).addClass('glyphicon glyphicon-star-empty');
			}
		}

		$(parentEle).after(clone);
		this.handleTransferCloneDayPeriod(clone);
		this.handleTransferCloneHours(clone);
	},

	removePromoPricesFromClone: function(cloneEl) {
		var promo_prices = $(cloneEl).find('.date-picker-row');

		promo_prices.each(function(i, el) {
			if(i > 0) $(el).remove();
		});
	},
	handleTransferCloneDayPeriod: function(clone) {
		var select = $(clone).find('.js-time-of-day');

		$(select).change(function() {
			$(this).closest('.row:not(.rowMargin)').find('.btn-info').attr('disabled', 'disabled');
			if($(this).val() === 'prepodne' || $(this).val() === 'poslepodne') {
				$(this).closest('.row-oprema').find('.durability').val('01:00');
				$(this).closest('.row-oprema').find('.btn-info.plus').removeAttr('disabled');
			}
			else if($(this).val() === 'ceodan') {
				$(this).closest('.row-oprema').find('.durability').val('08:00');;
				$(this).closest('.row-oprema').find('.btn-info.plus').removeAttr('disabled');
			}
			else $(this).closest('.row-oprema').find('.btn-info').attr('disabled', 'disabled');
			changeHours($(this).closest('.row-oprema').find('.btn-info.plus'), 'none');
		});
		this.handleTransferCloneHours(clone);
	},
	handleTransferCloneHours: function(clone) {
		var select = $(clone).find('.js-time-of-day');
		var btn = $(select).closest('.row-oprema').find('.btn-info');

		$(select).closest('.row-oprema').find('.btn-info').attr('disabled', 'disabled');

		$(btn).click(function() {
			var direction = $(this).hasClass('plus') ? 'plus' : 'minus';

			changeHours($(this), direction);
		});
	},
	/*
	 * 
	 * 
	 * 
	 * PRILIKOM VALIDACIJE NA NEKI KLIK POKRENUTI startValidation() koji vraca callback
	 * HTML :
	 * 1. attribute : num    		; POLJE CE BITI OBAVEZNO SAMO ZA BROJEVE
	 * 2. attribute : emp	 		; POLJE NE MOZE BITI PRAZNO
	 * 3. attribute : ema	 		; EMAIL VALIDACIJA
	 * 4. attribute : length="4"
	 * 
	 * PRIMER:
	 * 
	 * <input type="text" num emp length="4" name="nesto" class="form-control" text-not="nesto">
	 * 
	 */
	validacija: function (cb, tip) {
		var allElements = document.getElementsByTagName('*');
		var array = [];
		var flag = true;
		var flagine = false;
		if (tip != undefined) {
			for (var i = 0; i < allElements.length; i++) {
				if (allElements[i].getAttribute(tip) !== null) {
					array.push(allElements[i]);
				}
			}
		} else {
			for (var i = 0; i < allElements.length; i++) {
				if (allElements[i].getAttribute('num') !== null || allElements[i].getAttribute('emp') !== null || allElements[i].getAttribute('length') !== null || allElements[i].getAttribute('ema') !== null) {
					array.push(allElements[i]);
				}
			}
		}
		var arrError = [];
		var emptyElements = [];
		for (var i = 0; i < array.length; i++) {
            if (array[i].tagName == 'INPUT' || array[i].tagName == 'TEXTAREA' || array[i].tagName == 'SELECT') {
				if (array[i].type == 'text' || array[i].type == 'date' || array[i].type == 'password' || array[i].type == 'checkbox' || array[i].type == 'file' || array[i].type == 'email' || array[i].tagName == 'TEXTAREA' || array[i].tagName == 'SELECT') {
					
					if(!array[i].getAttribute('obj')){
						continue;
					}
					var flgaIn = true;
					var emp = pop.validacijaEmp(array[i]);
					if(!emp){
						emptyElements.push(array[i]);
					}
					var num = pop.validacijaNum(array[i]);
					var length = pop.validacijaLen(array[i]);
					var ema = pop.validacijaEmail(array[i]);
					var dur = pop.validacijaDuration(array[i]);
					var url = pop.validacijaUrl(array[i]);

					if (num == false || emp == false || length == false || ema == false || dur == false || url == false) {
						flag = false;
						flgaIn = false;
					}
					if (flgaIn) {
						if(array[i].type == 'file') $(array[i]).closest('label').removeClass('fieldAlert');
						$(array[i]).removeClass('fieldAlert');
						$(array[i]).parent().find('.alertValSpan').remove();
					} else {
						if (!$(array[i]).hasClass('fieldAlert')) {
							if(array[i].type == 'file') $(array[i]).closest('label').addClass('fieldAlert');

							$(array[i]).addClass('fieldAlert');
							var att;
							(emptyElements.includes(array[i])) ? att = $(array[i]).attr('text-not') :  att = $(array[i]).attr('text-wrong')
							
							if (att != null) {
							    arrError.push(att);
							}
							if (!flagine) {
								$(array[i]).focus();
								flagine = true;
							}
						} else {
							var att;
							(emptyElements.includes(array[i])) ? att = $(array[i]).attr('text-not') :  att = $(array[i]).attr('text-wrong')
                            if (att != null) {
                                arrError.push(att);
                            }
							if (!flagine) {
								$(array[i]).focus();
								flagine = true;
							}
						}
					}
				}
			}
		}
		
		if(arrError.length > 0){
            this.validacijaNotification(arrError,'client');
        }
		if (flag) {
			cb(true);
		} else {
			cb(false);
		}
	},
	validacijaNotification:function(arr,tip){
		var wrapDiv = document.createElement('div');
		wrapDiv.setAttribute('class','alert-fixed-wrap');
        var close = document.createElement('div');
		close.innerHTML='';
		close.setAttribute('class','alert-close');
		close.onclick=function(){
			$(wrapDiv).remove();
		};
        wrapDiv.appendChild(close);
	    if(tip == 'client'){
			var newArr = [];
	        for(var i = 0;i < arr.length; i++){
				if(newArr.indexOf(arr[i]) == -1){
					newArr.push(arr[i])
                }
            }
            if(newArr.length != 0){
				for(var i=0;i<newArr.length;i++){
                    var span = document.createElement('p');
						span.innerHTML = '<b style="color:red">* </b> '+newArr[i];
                    $(wrapDiv).append(span);
                }
            }
	    }else{
	        for(var key in arr){
				var msg = arr[key][0];
	            var span = document.createElement('p');
                    span.innerHTML = '<b style="color:red">* </b> '+msg;
                $(wrapDiv).append(span);
	        }
	    }
	    $('.alert-fixed').append(wrapDiv);
        setTimeout(function(){
            $(wrapDiv).remove();
        },6000);  
	},
	validacijaSet: function () {

	},
	validacijaNum: function (ele) {
		var num = ele.getAttribute('num');
		if (num != null) {
			var val = ele.value;
			if (isNaN(val)) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	},
	validacijaDuration: function (ele) {
		
		var duration = ele.getAttribute('dur');
			
		if(duration == ''){
			var val = duration.value;
			if(val == '00:00'){
				return false;
			}
		}
		return true;
	},
	validacijaUrl: function(ele) {
		if(ele.hasAttribute('url')){
			if(ele.value.startsWith('http')) {
				return true;
			} else {
				return false;
			}
		};
		return true;
	},

	validacijaEmp: function (ele) {
		var emp = ele.getAttribute('emp');
		if (emp != null) {
			var val = ele.value;
			if (val == '') {
				return false;
			}
			if (ele.type == 'checkbox') {
				if (!ele.checked) {
				    var name = ele.getAttribute("name");
				    var flag = false;
				    $('[name="'+name+'"]').each(function(){
				       if(this.checked){
				           flag = true;
				       } 
				    });
				    if(flag){
						var label = $(ele).parent().find('label:first');
						$(label).removeClass('beforeAlertCheck');
				       return true; 
				    }else{
						var label = $(ele).parent().find('label:first');
						$(label).addClass('beforeAlertCheck');
				       return false;
				    }
				} else {
					var label = $(ele).parent().find('label:first');
					$(label).removeClass('beforeAlertCheck');
					return true;
				}
			} else {
				return true;
			}
		} else {
			return true;
		}
	},
	validacijaLen: function (ele) {
		var length = ele.getAttribute('length');
		if (length != null) {
			var val = ele.value;
			if (val.length <= length) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	},
	validacijaEmail: function (ele) {
		var email = ele.getAttribute('ema');
		if (email != null) {
			var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			var val = ele.value;
			if (re.test(val)) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	},
	userExists: function(email) {
		var self = this;
		$.ajax({
			url: '/check-if-user-exists/'+email,
			method: 'GET',
			success: function(data) {
				if(data.email === undefined) {
					$('#subForm').submit();
				}
				else {
					$('#user_email, #user_email_confirm').addClass('fieldAlert');
					$('body, html').animate({scrollTop: $('#user_email').offset().top - $('header').height()});
					self.validacijaNotification(['Korisnik sa ovom mejl adresom već postoji!'], 'client');
				}
			}
		});
	},
	confirmEmail: function() {
		var email = $('#user_email').val();
		var email_confirm = $('#user_email_confirm').val();

		if(email !== email_confirm) {
			$('#user_email, #user_email_confirm').addClass('fieldAlert');
			$('body, html').animate({scrollTop: $('#user_email').offset().top - $('header').height()});
			this.validacijaNotification(['Potvrdite tačnu mejl adresu!'], 'client');
			return false;
		}
		
		if($('#user_email_confirm').hasClass('fieldAlert'));
			$('#user_email, #user_email_confirm').removeClass('fieldAlert');
		return true;
	},
	uploadedFilesValidation: function(elements) {
		var validation = true;
		var self = this;
		elements.each(function(i, el) {
			if($(el).val() === '') return;

			var allowed_ext    = $(el).attr('file-ext').split(',');
			var file_name_arr  = $(el).val().split('\\');
			var file_ext_arr   = file_name_arr[2].split('.');
			var file_extension = file_ext_arr[file_ext_arr.length -1];

			if(!allowed_ext.includes(file_extension)) {
				validation = false;
				$('body, html').animate({scrollTop: $(el).closest('.row').offset().top - $('header').height()});
				$(el).closest('label').css('border', '1px solid red');
				self.validacijaNotification(['Dozvoljeno tipovi fajla su: '+allowed_ext.join(',')], 'client');
			} else {
				$(el).closest('label').css('border', 'none');
			}
			
		});

		return validation;
	},
	/*
	 * 
	 * 2 PARAMETRA
	 * 
	 * 1.input element
	 * 2.plus ili minus
	 * 
	 * PRIMER: 
	 * 
	 * 
	 * <div class="input-group">
		  <span class="input-group-btn">
	        <button class="btn btn-info" type="button" onclick="pop.setTimePlusMin(this,'minus')"><span class="glyphicon glyphicon-minus"></span></button>
	      </span>
	      <input type="text" name="" clone="clearClone" class="form-control" maxlength="5" value="00:00" onkeyup="pop.setTimePlusMin(this)">
	      <span class="input-group-btn">
	        <button class="btn btn-info" type="button" onclick="pop.setTimePlusMin(this,'plus')"><span class="glyphicon glyphicon-plus"></span></button>
	      </span>
	    </div>
	 * 
	 */

	setTimePlusMin: function (ele, operation, event) {
		var x = event || window.event;
		var flag;

		if (ele.tagName == 'BUTTON') {
			var ele = $(ele).parent().parent().find('input');
			flag = false;
			var val = ele[0].value;
		} else {
			flag = true;
			var val = ele.value;
		}
		var inputtime = $(ele).val().split(':');
		if(inputtime[0] == 23 && inputtime[1] == 59 && operation === 'plus') {
			return false;
		}
		if (x.keyCode != 8) {
			if (flag) {
				var len = val.length;
				switch (len) {
					case 1:
						var num = parseInt(val);
						if (num >= 3 || isNaN(num)) {
							ele.value = '';
						}
						break;
					case 2:
						var num = parseInt(val);
						if (isNaN(num)) {
							var newVal = val.slice(0, -1);
							ele.value = newVal;
						} else {
							num = parseInt(val);
							if (num >= 24) {
								var newVal = val.slice(0, -1);
								ele.value = newVal;
							} else {
								ele.value += ':';
							}
						}
						break;
					case 4:
						var LastNum = val.charAt(val.length - 1);
						var num = parseInt(LastNum);
						if (isNaN(num) || num >= 7) {
							var newVal = val.slice(0, -1);
							ele.value = newVal;
						}
						break;
					case 5:
						var LastNum = val.charAt(val.length - 1);
						num = parseInt(LastNum);
						if (isNaN(num)) {
							var newVal = val.slice(0, -1);
							ele.value = newVal
						} else {
							var LastNum = val.slice(-2);
							num = parseInt(LastNum);
							if (num >= 60) {
								var newVal = val.slice(0, -1);
								ele.value = newVal
							}
						}
						break;
				}
			} else {
				if (val.length == 5) {

					var LastNum = val.slice(-2);
					var numLast = parseInt(LastNum);
					var FirstNum = val.substring(0, 2);
					var numFirst = parseInt(FirstNum);

					if (numLast == 00) {
						if (operation == 'plus') {
							var sumLast = '15';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '45';
							var sumFirst = numFirst - 1;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 1 && numLast <= 5) {
						if (operation == 'plus') {
							var sumLast = '15';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '45';
							var sumFirst = numFirst - 1;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast > 6 && numLast <= 11) {
						if (operation == 'plus') {
							var sumLast = '15';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '00';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 12 && numLast <= 17) {
						if (operation == 'plus') {
							var sumLast = '30';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '00';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 18 && numLast <= 23) {
						if (operation == 'plus') {
							var sumLast = '30';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '15';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 24 && numLast <= 29) {
						if (operation == 'plus') {
							var sumLast = '45';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '15';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 30 && numLast <= 36) {
						if (operation == 'plus') {
							var sumLast = '45';
							var sumFirst = FirstNum;
						} else {
							var sumLast = '15';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 37 && numLast <= 43) {
						if (operation == 'plus') {
							var sumLast = '45';
							var sumFirst = numFirst;
						} else {
							var sumLast = '30';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 44 && numLast <= 49) {
						if (operation == 'plus') {
							var sumLast = '00';
							var sumFirst = numFirst + 1;
						} else {
							var sumLast = '30';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					} else if (numLast >= 50 && numLast <= 59) {
						if (operation == 'plus') {
							var sumLast = '15';
							var sumFirst = numFirst + 1;
						} else {
							var sumLast = '45';
							var sumFirst = FirstNum;
						}
						val = pop.checkValidTimePlusMin(sumFirst, sumLast, val);
						if (val != false) ele[0].value = val;
					}
				}
			}
		}
		// 
		if ($('.js-form-active').length > 0) {
			var sum = ele.closest('.add-transfer-wrapper').find('.sum-hour').val();
			if (operation == 'plus') {
				if (sum != '') {
					var sumSpl = sum.split(':');
					var valSpl = val.split(':');
					var hour = parseInt(sumSpl[0]);
					var minutes = parseInt(sumSpl[1]) + 15;
					if (minutes > 59) {
						hour += 1;
						minutes = '00';
					}
					if (hour <= 9) {
						hour = '0' + hour;
					}
					var total = hour + ':' + minutes;
					ele.closest('.add-transfer-wrapper').find('.sum-hour').val(total);
				} else {
					ele.closest('.add-transfer-wrapper').find('.sum-hour').val(val);
				}
			} else {
				var sumSpl = sum.split(':');
				var valSpl = val.split(':');
				var hour = parseInt(sumSpl[0]);
				var minutes = parseInt(sumSpl[1]) - 15;
				if (minutes < 0) {
					hour -= 1;
					minutes = '45';
				} else {
					if (minutes == 0) {
						minutes = '00';
					}
				}
				if (hour <= 9) {
					hour = '0' + hour;
				}
				var total = hour + ':' + minutes;

				ele.closest('.add-transfer-wrapper').find('.sum-hour').val(total);
			}
		}
	},
	checkValidTimePlusMin: function (sumFirst, sumLast, val) {
		if (sumFirst == 0) {
			sumFirst = "00";
		}
		if (sumFirst.toString() == "-1" && sumLast.toString() == "45"){
		    val = '23:45';
		    return val;
		}
		if (sumFirst.toString() == "24" && sumLast.toString() == "00"){
		    val = '00:00';
		    return val;
		}

		if (sumFirst <= 9 && sumFirst != 00 && sumFirst.length != 2 && sumFirst != "00") {
			val = '0' + sumFirst + ':' + sumLast;
		} else {
			val = sumFirst + ':' + sumLast;
		}

		return val;
	},
	/*
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 */
	setPlusMinusBy: function (ele, operation, by, event) {
		var x = event || window.event;
		var flag;
		if (ele.tagName == 'BUTTON') {
			var ele = $(ele).parent().parent().find('input');
			flag = false;
			var val = ele[0].value;
		} else {
			flag = true;
			var val = ele.value;
		}

		if (x.keyCode != 8) {
			if (flag) {
				// input ostavljen ukoliko bude trebalo zabraniti da ide u minus ...
			} else {
				// button
				if (operation == 'plus') {
					if (val == '') {
						val = 0;
					} else {
						val = parseInt(val);
					}
					var sum = val + by;
					ele[0].value = sum;
				} else {
					if (val == '') {
						val = 0;
					} else {
						val = parseInt(val);
					}
					var sum = val - by;
					ele[0].value = sum;
				}
			}
		}
	},
	/*
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * PRIMER :
	 * 
	 * 
	 * <div class="col-md-2">
			<i class="glyphicon glyphicon-star-empty" clone="clearClone" onclick="pop.starRating(this,'click')" onmouseover="pop.starRating(this)" onmouseleave="pop.starRating(this,'leave')" data-rating="1"></i>
	        <i class="glyphicon glyphicon-star-empty" clone="clearClone" onclick="pop.starRating(this,'click')" onmouseover="pop.starRating(this)" onmouseleave="pop.starRating(this,'leave')" data-rating="2"></i>
	        <i class="glyphicon glyphicon-star-empty" clone="clearClone" onclick="pop.starRating(this,'click')" onmouseover="pop.starRating(this)" onmouseleave="pop.starRating(this,'leave')" data-rating="3"></i>
	        <i class="glyphicon glyphicon-star-empty" clone="clearClone" onclick="pop.starRating(this,'click')" onmouseover="pop.starRating(this)" onmouseleave="pop.starRating(this,'leave')" data-rating="4"></i>
	        <i class="glyphicon glyphicon-star-empty" clone="clearClone" onclick="pop.starRating(this,'click')" onmouseover="pop.starRating(this)" onmouseleave="pop.starRating(this,'leave')" data-rating="5"></i>
		</div>
		<input type="text" name="" clone="clearClone" class="starValue" />
	 * 
	 * 
	 */
	starRating: function (ele, eve) {
		var par = ele.parentNode;
		var niz = par.children;
		var starNum = ele.getAttribute('data-rating');
		var starinput = document.getElementById('starRate');
		if (eve == 'click') {
			for (var i = 0; i < niz.length; i++) {
				if (i >= starNum) {
					niz[i].setAttribute('class', 'js-star-icons glyphicon glyphicon-star-empty');
				} else {
					niz[i].setAttribute('class', 'js-star-icons glyphicon glyphicon-star');
					$(niz[i]).addClass('starColorAdd');
				}
			}
		} else {
			for (var i = 0; i < niz.length; i++) {
				if (i >= starNum) {
					if ($(niz[i]).hasClass('glyphicon glyphicon-star-empty')) {
						$(niz[i]).removeClass('starColorAdd');
					}
				} else {
					if ($(niz[i]).hasClass('glyphicon glyphicon-star-empty')) {
						$(niz[i]).addClass('starColorAdd');
					}
					if (eve == 'leave') {
						if ($(niz[i]).hasClass('glyphicon glyphicon-star-empty')) {
							$(niz[i]).removeClass('starColorAdd');
						}
					}
				}
			}
		}
		if (eve == 'click') {
			var parent = $(ele).parent().parent();
			var starinput = $(parent).find('.starValue');
			var attr = ele.getAttribute('data-rating');
			$(starinput).val(attr);
		}
	},
	compareStarRating: function (ele) {
		var star = $('.js-star-icons');
		var val = parseInt(ele.value);
		if(Number.isInteger(val)) {
			starIndex = pop.calculateStarRating(val) - 1;
			var starEle = star.eq(starIndex)[0];
			
			pop.starRating(starEle, 'click');
		} else {
			pop.starRating(star.eq(0)[0], 'click');
		}
	},
	calculateStarRating: function (val) {
		if(val < 68){
			return 1;
		} else if(val >= 68 && val < 74){
			return 3;
		} else if(val >= 74 && val < 81){
			return 4;
		} else {
			return 5;
		}
	},
	/*
	 * 
	 * 
	 * Prikaz single slike pre uploda
	 * 3 parametra:
	 * 
	 * 1.provera fajla preko inputa
	 * 2.gde appendujemo slike
	 * 3.dodeljivanje klase za sliku
	 * 
	 * 
	 */
	readSingleImg: function (input, classApp, imgClass) {
		var filePath = input.value;
		var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
		var checkLen = $('.singleImgWrap').parent().find('img');
		if (!allowedExtensions.exec(filePath)) {
			pop.popUpMsg('Danger', 'Format slike nije dobar. Mogući format (png,jpg,jpeg)');
			return false;
		}
		if (checkLen.length >= 1) {
			$(checkLen)[0].remove();
		}
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				var par = $(input).parent().parent().parent();
				var col = $(par).find('.' + classApp);
				var img = document.createElement('img');
				img.setAttribute('src', e.target.result);
				img.setAttribute('class', 'img-responsive img-thumbnail');
				$(col).append(img);
			}
			reader.readAsDataURL(input.files[0]);
			$('.singleImgWrap .closeImg').on('click', function () {
				input,
				value = '';
				$(this).parent().parent().find('img').remove();
				$(this).parent().parent().css('background', 'url("../../images/emptyImg.gif")');
				$(this).parent().parent().css('background-size', '100% 100%');
			});
		}
	},
	/*
	 * 
	 * 
	 * Prikaz single slike pre uploda
	 * 2 parametra:
	 * 
	 * 1.provera fajla preko inputa
	 * 2.gde appendujemo file
	 * 3.ukoliko se edituje tura prosledjuje se true
	 * 4.vrednost uploadovanog dokumenta pri editu ture
	 * 
	 */
	uploadFile: function (input, classApp, edit, value) {
		var edit = edit || 0;
		var value = value || '';

		if (!edit) {
			var filePath = input.value;
			var allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
			if (!allowedExtensions.exec(filePath)) {
				pop.popUpMsg('Danger', 'Format fajla nije dobar. Mogući formati (pdf,doc,docx)');
				return false;
			} else {
				// p is file name displayed to the user
				var p = $(input).parent().parent().parent().find('.' + classApp);
				p[0].innerHTML = input.files[0].name;
				// make remove icon and append it to the file name
				var icon = document.createElement('span');
				icon.classList.add('icon-remove');
				icon.classList.add('icon-remove--pdf');
				p[0].appendChild(icon);

				// bind to click: remove uploaded file and it's name
				icon.addEventListener('click', function () {
					input.value = '';
					p[0].innerHTML = '';
				}, false);
			}
		} else {
			// p is file name displayed to the user
			var p = $(input).parent().parent().parent().find('.' + classApp);
			p[0].innerHTML = '<a href="/documents/' + value + '" target="_blank">Pregled</a>';
			// make remove icon and append it to the file name
			var icon = document.createElement('span');
			icon.classList.add('icon-remove');
			icon.classList.add('icon-remove--pdf');
			p[0].appendChild(icon);

			// bind to click: remove uploaded file and it's name
			icon.addEventListener('click', function () {
				if (confirm('Are you want to delete uploaded file?')) {
					input.value = '';
					p[0].innerHTML = '<input type="hidden" name="removeFile[]" value="' + value + '">';
				}
			}, false);
		}

	},

	/*
	 *
	 * Remove uploaded file
	 */
	removeUploadFile: function (input) {
		console.log('remove upload file', input)
		// $(input).after($(this).clone(true)).remove();
	},
	/*
		Images holder
	 */
	allImages: [],
	allImagesStringified: function () {
		var response = [];

		for (var i = 0; i < this.allImages.length; i++) {

			response.push(JSON.stringify(this.allImages[i]));

		}

		return response;
	},
	/*
	 *
	 *
	 * Prikaz multi slika pre uploda
	 * 2 parametra:
	 *
	 * 1.provera fajla preko inputa
	 * 2.gde appendujemo slike
	 *
	 *
	 */
	readMulImg: function (input, classApp) {
		var flag = true;
		var $container = $('.' + classApp);



		if (input.files && input.files[0]) {

			// count the images
			var filesAmount = input.files.length;

			// check for extensions
			for (var i = 0; i < filesAmount; i++) {
				//var ext = input.files[i].name.substr(-3);

				// if (ext !== "jpg" && ext !== "jpeg" && ext !== "png" && ext !== "PNG" && ext !== "JPG" && ext !== "JPEG") {
				// 	flag = false;
				// }
				var filePath = input.files[i].name;
				var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

				if(!allowedExtensions.exec(filePath)) {
					flag = false;
				}

			}

			// if extensions are ok
			if (flag) {

				// loop through the images
				for (var z = 0; z < filesAmount; z++) {

					// push new images to allImages holder

					var reader = new FileReader();

					reader.name = input.files[z].name;
					reader.lastModified = input.files[z].lastModified;
					reader.lastModifiedDate = input.files[z].lastModifiedDate;
					reader.size = input.files[z].size;
					reader.type = input.files[z].type;
					reader.z = z;

					reader.onload = function (ev) {

						$container.append('' +
							'<div class="multiImg">' +
							'<div class="imgContainer" style="background-image: url(' + ev.target.result + ')"> ' +
							'</div>' +
							'<div class="deleteImg" data-img="' + ev.target.name + '">' +
							'<i class="glyphicon glyphicon-remove"></i>' +
							'</div>' +
							'</div>');

						pop.allImages.push({
							'lastModified': ev.target.lastModified.toString(),
							'lastModifiedDate': ev.target.lastModifiedDate,
							'name': ev.target.name,
							'size': ev.target.size.toString(),
							'type': ev.target.type,
							'image': ev.target.result
						});
					};

					reader.readAsDataURL(input.files[z]);

				}

			} else {
				pop.popUpMsg('Danger', 'Format slike nije dobar. Mogući format (png,jpg,jpeg)');
			}
		}
	},
	/*
	 *
	 *
	 * Vracanje slika pri editovanju ture
	 * 2 parametra:
	 *
	 * 1.uploadovane slike ture
	 * 2.gde appendujemo slike
	 * j
	 *
	 */
	returnUploadedImg: function (images, classApp) {
		var $container = $('.' + classApp);
		// loop through the images
		for (var z = 0; z < images.length; z++) {

			// push new images to allImages holder

			$container.append('' +
				'<div class="multiImg">' +
				'<div class="imgContainer" style="background-image: url(/images/tours-images/' + images[z].thumb + ')"> ' +
				'</div>' +
				'<div class="deleteImg" data-img="' + images[z].name + '">' +
				'<i class="glyphicon glyphicon-remove"></i>' +
				'</div>' +
				'<input type="hidden" name="gallery_uploaded_holder[]" value="' + images[z].thumb + '" />' +
				'</div>');

		}
	},
	/*
	 *
	 *
	 * Vracanje uploadovane naslovne slike pri editovanju ture
	 * 2 parametra:
	 *
	 * 1.uploadovana cover slika ture
	 * 2.file input
	 * 3.gde appendujemo slike
	 * 4.klasa na img element
	 * j
	 *
	 */
	returnUploadedCover: function (image, input, classApp, imgClass) {
		var par = $('input[name="' + input + '"]').parent().parent().parent();
		var col = $(par).find('.' + classApp);
		var img = document.createElement('img');
		img.setAttribute('src', '/images/tours-images/' + image.thumb);
		img.setAttribute('class', 'img-responsive img-thumbnail ' + imgClass);
		$(col).append(img);
		$(col).append('<input type="hidden" name="cover_holder" value="' + image.thumb + '" />');

		$('.singleImgWrap .closeImg').on('click', function () {
			$(this).parent().parent().find('img').remove();
			$(this).parent().parent().find('input[name="cover_holder"]').remove();
			$(this).parent().parent().append('<input type="hidden" name="remove_cover_holder" value="' + image.thumb + '" />');
			$(this).parent().parent().css('background', 'url("/images/emptyImg.gif")');
			$(this).parent().parent().css('background-size', '100% 100%');
		});
	},
	/*
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 * 
	 */
	ajaxLoding: function (metod) {
		if (metod != undefined) {
			if (metod == 'start') {
				var div = document.createElement('div');
				div.setAttribute('class', 'circleAjaxLoading');
				var div1 = document.createElement('div');
				div1.setAttribute('class', 'circle1AjaxLoading1');
				var div2 = document.createElement('div');
				div2.setAttribute('class', 'boxAjaxLoading1');
				var wrap = document.createElement('div');
				wrap.setAttribute('class', 'wrapAjaxLoading');
				div2.appendChild(div);
				div2.appendChild(div1);
				wrap.appendChild(div2);
				var body = document.getElementsByTagName('body');
				body[0].appendChild(wrap);
			} else {
				var div = document.getElementsByClassName('wrapAjaxLoading');
				div[0].parentNode.removeChild(div[0]);
			}
		} else {
			console.log('ajaxLoding must have included all parameter');
		}
	},

	/*
	 * 
	 * 
	 * 
	 * PRIMER:
	 * 
	 * <div class="alert" id="popupId"></div>
	 * pop.popUpMsg('Info',' neki info');
	 * pop.popUpMsg('Danger',' nesto nije dobro');
	 * pop.popUpMsg('Success',' Odlicno');
	 * 
	 */
	popUpMsg: function (tip, msg) {
		var wrap = document.createElement('div');
		wrap.setAttribute('class', 'popUpMsg');
		var tipWrap = document.createElement('div');
		var p = document.createElement('p');
		switch (tip) {
			case 'Info':
				wrap.setAttribute('class', 'popUpMsg popupInfo');
				tipWrap.innerHTML = '<h2>Obaveštenje!</h2>';
				break;
			case 'Danger':
				wrap.setAttribute('class', 'popUpMsg popupDanger');
				tipWrap.innerHTML = '<h2>Pažnja!</h2>';
				break;
			case 'Success':
				wrap.setAttribute('class', 'popUpMsg popupSuccess');
				tipWrap.innerHTML = '<h2>Uspelo!</h2>';
				break;
		}
		p.innerHTML = msg;
		wrap.appendChild(tipWrap);
		wrap.appendChild(p);
		document.getElementsByTagName("body")[0].appendChild(wrap);
		setTimeout(function () {
			var parent = wrap.parentNode;
			parent.removeChild(wrap);
		}, 5000);
	},
	ajax: function (url, ty, data, cb) {
		$.ajax({
			url: url,
			cache: false,
			type: ty,
			data: data,
			/*contentType: "application/json",
			dataType: 'json',*/
			success: function (result) {
				cb(result);
			},
			error: function (error) {
				cb(error);
			}
		});
	},
	setAutoComplite: function (ele, data) {
		var div = $(ele).parent().find('.autocomplite');
		var ul = document.createElement('ul');
		for (var i = 0; i < data.length; i++) {
			var li = document.createElement('li');
			li.innerHTML = data[i];
			li.onclick = function () {
				var liVal = this.innerHTML;
				ele.value = liVal;
				$(div).hide();
			}
			ul.appendChild(li);
		}
		div[0].appendChild(ul);
	},
	autoComplite: function (ele, val, event) {
		var key = event.keyCode;
		var li = $(ele).parent().find('.autocomplite ul li');
		var ul = $(ele).parent().find('.autocomplite ul');
		var activLi = $(ele).parent().find('.autocomplite ul .active');
		var container = $(ele).parent().find('.autocomplite');
		var flag = true;

		switch (key) {
			case 40:
				flag = false;
				if (!$(container).is(':visible')) {
					return false;
				}
				if (activLi.length == 0) {
					$(ele).parent().find('.autocomplite ul li:visible').first().addClass('active');
				} else {
					$(activLi).removeClass('active');
					$(activLi).nextAll("li:visible").first().addClass('active');
					ul[0].scrollTop = (activLi[0].offsetTop - 50);
				}
				break;
			case 38:
				flag = false;
				if (!$(container).is(':visible')) {
					return false;
				}
				if (activLi.length == 0) {
					$(ele).parent().find('.autocomplite ul li:visible').last().addClass('active');
				} else {
					$(activLi).removeClass('active');
					$(activLi).prevAll("li:visible").first().addClass('active');
					ul[0].scrollTop = (activLi[0].offsetTop - 100);
				}
				break;
			case 13:
				flag = false;
				if (!$(container).is(':visible')) {
					return false;
				}
				if (activLi.length == 0) {
					return false;
				} else {
					var liVal = $(activLi).text();
					ele.value = liVal;
					$(container).hide();
				}
				break;
		}

		if (flag) {
			val = val.toUpperCase();
			for (var i = 0; i < li.length; i++) {
				$(li[i]).removeClass('active');
				if (val != '') {
					var str = li[i].innerHTML;
					str = str.toUpperCase();
					if (str.indexOf(val) > -1) {
						$(li[i]).show();
						$(ele).parent().find('.autocomplite').show();
					} else {
						$(li[i]).hide();
					}
				} else {
					$(li[i]).hide();
					$(ele).parent().find('.autocomplite').hide();
				}
			}
		}

	}
}

function googlePlacesAutocomplete(input, countryId) {
	
	var country = null;
	if(countryId != '') {
		country = document.getElementById(countryId);
	}

	var options = {
		types: ['(cities)'],
		//componentRestrictions: {country: ['rs', 'ba', 'hr', 'si', 'at', 'me', 'mk']}
	};
	
	var autocomplete = new google.maps.places.Autocomplete(input, options);

	google.maps.event.addListener(autocomplete, 'place_changed', function() {
	        input.value = '';
			
			var place = autocomplete.getPlace();
			var address = place.formatted_address.split(', ');
			input.value = address[0];

			$(input).attr('autocomplete-selected', true);
			
			if(country != null) {
				country.value = address[1];
			}
	});
	
}


jQuery(function ($) {
	$('[name="city"]').blur(function (e) {
		if (!$(this).attr('autocomplete-selected')) {
			//$(this).attr('placeholder', '');
			//$(this).val('');
		}
	});

	$('.only-int-numeric').on('keypress', function(e) {
		if (e.which < 48 || e.which > 57)
		{
			e.preventDefault()
		}
	});
});

