function schedulesfilter(str) {

    if (str.length==0) {

        return;
    }
    if (window.XMLHttpRequest) {
        // code for IE7+, Firefox, Chrome, Opera, Safari
        xmlhttp=new XMLHttpRequest();
    } else {  // code for IE6, IE5
        xmlhttp=new ActiveXObject("Microsoft.XMLHTTP");
    }
    xmlhttp.onreadystatechange=function() {

        var location = JSON.parse(xmlhttp.responseText);
        console.log(location.start_location);
        if (this.readyState==4 && this.status==200) {

            $('#livesearchfrom').text(location[0].start_location);

        }

    }
    xmlhttp.open("GET","/schedules/yneed-time-from/"+str,true);
    xmlhttp.send();


}
