jQuery(function ($) {
	
	var windowWhidth = window.innerWidth;
	var $window = $(window);

	/*-------------------------------------------------------------------------------------
		# Slick
	-------------------------------------------------------------------------------------*/
	$('#footer-bann').slick({
		infinite      : true,
		slidesToShow  : 1,
		slidesToScroll: 1,
		dots          : true,
		arrows        : false
	});

    $('.remove-img-span').click(function(){
        $(this).toggleClass('active');
    });

    $("a.fancybox").fancybox({
        'showNavArrows' :   true
    });
	/*-------------------------------------------------------------------------------------
		# jQuery UI sliderRange
	-------------------------------------------------------------------------------------*/
	function sliderRange(selector) {
		var slider = $(selector).find('.slider-range');
		var input = $(selector).find('input');
		slider.slider({
			range: "min",
			value: selector.data('val'),
			min  : selector.data('min'),
			max  : selector.data('max'),
			slide: function (event, ui) {
				input.val(ui.value + selector.data('type'));
			}
		});
		input.val(slider.slider('value') + selector.data('type'));
	}
	
	$('.input-range').each(function () {
		sliderRange($(this));
	});
	
	/*-------------------------------------------------------------------------------------
		# Price Per Day
	-------------------------------------------------------------------------------------*/
	var highestPrice = 0;
	$('.price-per-day').each(function () {
		var price = $(this).data('price');
		highestPrice = price > highestPrice ? price : highestPrice;
	});
	
	$('.price-per-day').each(function () {
		var price = $(this).data('price');
		var height = price / highestPrice * 100;
		$(this).next('.price-bar').animate({'height': height + '%'}, 1000);
	});



    /*-------------------------------------------------------------------------------------
        # Tooltip home banner
    -------------------------------------------------------------------------------------*/

    
	/*-------------------------------------------------------------------------------------
		# Single Bus Options
	-------------------------------------------------------------------------------------*/
	$('.chose-bus-btn').on('click', function (e) {
		var row = $(this).parents('.form-row');
		var rows = $('.chose-bus-btn').parents('.form-row');
		var radio = rows.find('input[type="radio"]');
		
		if (row.hasClass('checked-row')) {
			e.preventDefault();
			rows.removeClass('checked-row');
			radio.prop("checked", false);
		} else {
			rows.removeClass('checked-row');
			row.addClass('checked-row');
		}
	});
	
	$('.alert.message').fadeIn('fast');
	
	setTimeout(function () {
		$('.alert.message').fadeOut('fast');
	}, 5000);
	
	
	/*-------------------------------------------------------------------------------------
		# Language Switcher
	-------------------------------------------------------------------------------------*/
	$('.lang-switch').hover(function () {
		$('ul.langs').fadeIn('fast');
	}, function () {
		$('ul.langs').fadeOut('fast');
	});

	 
});

