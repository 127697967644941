(function($) {

	var jtest = $('[jcountry]'),
		list = '';

	$.ajax({
		url: 'js/countriesToCities.json',
		success: function(data) {
			var i = 0;
			$.each(data, function(c) {
				if (c !== '') {
					list += '<option value="' + c + '" data-index="' + i + '">' + c + '</option>';
				}
				i++;
			})

			jtest.append(list);

			$('.country-search').on('input', function() {
				var term  = $(this).val().toLowerCase(),
					item  = jtest.find('option');

				$('[jcountry] > option').each(function() {
					var value = $(this).val().toLowerCase();

					if (value.indexOf(term) !== -1) {
						$('[jcountry] > option').attr('selected', false);
						$(this).attr('selected', true);
					}
				});

				var country = jtest.val(),
					n = 0,
					j = '';
  					j+='<label>Start city</label>'
				j += '<select name="start_location" id="" class="select-city form-control">';
				$.each(data[country], function() {
					j += '<option value="data[country][n]">' + data[country][n] + '</option>';
					alert(j);
					n++;
				});
				j += '</select>';

				$('#city').html('');
				$('#city').append(j);
				
			}).on('focusout', function() {
				
			});

			jtest.on('change', function() {
				var country = $(this).val(),
					n = 0,
					j;

				$('.country-search').val('');

				j += '<select class="select-city">';
				$.each(data[country], function() {
					j += '<option>' + data[country][n] + '</option>';
					n++;
				});
				j += '</select>';

				$('[jcity]').html('');
				$('[jcity]').append(j.substring(9));

			})
		}
	});
	
})(jQuery)