jQuery(function ($) {


    /************terms***************/
    $('.pay-row-cards-wrap').click(function () {
        $(this).toggleClass('clicked');
        var inp = $(this).find('input');
        if (inp.attr('checked') === 'checked') {
            $(this).find('input').attr('checked', false);
        } else {
            $(this).find('input').attr('checked', true);
        }
    });



    $('.buses-checkbox').click(function () {
        $(this).toggleClass('buses-checkbox-checked');
        if ($(this).hasClass('buses-checkbox-checked')) {
            $(this).parent().parent().parent().find('.terms-short-input , .terms-input-text').attr('required', true);
        } else {
            $(this).parent().parent().parent().find('.terms-short-input , .terms-input-text').attr('required', false);
        }
        if ($('.free-terms-checkbox').hasClass('buses-checkbox-checked')) {
            $('.price-free-terms-input').attr('required', true);
        } else {
            $('.price-free-terms-input').attr('required', false);
        }
        if ($('#advance-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.advance-payment-alert').hide();
        }
        if ($('#canceled-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.canceled-payment-alert').hide();
        }
        if ($('#rest-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.rest-payment-alert').hide();
        }
        if ($('#garanted-advanced-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-advanced-pay-alert').hide();
        }
        if ($('#garanted-canceled-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-canceled-pay-alert').hide();
        }
        if ($('#garanted-rest-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-rest-pay-alert').hide();
        }
        if ($('#kind-of-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.kind-of-pay-alert').hide();
        }

    });



    $('#terms-validation-submit').click(function () {
        if (!$('#advance-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.advance-payment-alert').show();
            $('#advance-payment .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#advance-payment .buses-checkbox').attr('required', false);
        }
        if (!$('#canceled-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.canceled-payment-alert').show();
            $('#canceled-payment .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#canceled-payment .buses-checkbox').attr('required', false);
        }
        if (!$('#rest-payment .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.rest-payment-alert').show();
            $('#rest-payment .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#rest-payment .buses-checkbox').attr('required', false);
        }
        if (!$('#garanted-advanced-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-advanced-pay-alert').show();
            $('#garanted-advanced-pay .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#garanted-advanced-pay .buses-checkbox').attr('required', false);
        }
        if (!$('#garanted-canceled-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-canceled-pay-alert').show();
            $('#garanted-canceled-pay .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#garanted-canceled-pay .buses-checkbox').attr('required', false);
        }
        if (!$('#garanted-rest-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.garanted-rest-pay-alert').show();
            $('#garanted-rest-pay .buses-checkbox').attr('required', true);
            $('#terms-form-validation .form-box .terms-inputs .terms-inputs-left .with-errors').css('display', 'none');
        } else {
            $('#garanted-rest-pay .buses-checkbox').attr('required', false);
        }
        if (!$('#kind-of-pay .buses-checkbox').hasClass('buses-checkbox-checked')) {
            $('.kind-of-pay-alert').show();
            $('#kind-of-pay .buses-checkbox').attr('required', true);
        } else {
            $('#kind-of-pay .buses-checkbox').attr('required', false);
        }
        if ($('.pay-cards-container input').hasClass('card-input-open')) {
            $('.credit-card-alert').show();
            $('.pay-cards-container input').attr('required', true);
        } else {
            $('.credit-card-alert').hide();
            $('.pay-cards-container input').attr('required', false);
        }
        if ($('#checkbox-pay-paypall input').attr('required')) {
            $('.paypall-alert').show();
        } else {
            $('.paypall-alert').hide();
        }

        if ($('#checkbox-pay-fromcount input').attr('required')) {
            $('.fromcount-alert').show();
        } else {
            $('.fromcount-alert').hide();
        }
    });

    $('.checkbox-pay-div').hide();
    $('.buses-checkbox-pay').click(function () {

        $(this).toggleClass('open');

        if ($('.terms-creditcard-input').hasClass('open')) {
            $('#checkbox-pay-creditcard').show().find('.pay-row-cards-wrap').removeClass('clicked');
            $('.pay-cards-container input').addClass('card-input-open');
            if ($('.pay-cards-container input').hasClass('card-input-open')) {
                $('.pay-cards-container input').attr('required', true);
            } else {
                $('.pay-cards-container input').attr('required', false);
            }
            $('.pay-cards-container input').click(function () {
                if ($('.pay-cards-container input:checked').length > 0) {
                    $('.pay-cards-container input').removeClass('card-input-open');
                    $('.pay-cards-container input').attr('required', false);
                } else {
                    $('.pay-cards-container input').addClass('card-input-open');
                    $('.pay-cards-container input').attr('required', true);
                }
            });

        } else {
            $('#checkbox-pay-creditcard').hide();
            $('#checkbox-pay-creditcard').hide().find('.pay-row-cards-wrap').addClass('clicked');
            $('.pay-cards-container input').removeClass('card-input-open');
            $('.pay-cards-container input').attr('required', false);
        }

        if ($('.terms-creditcard-paypall').hasClass('open')) {
            $('#checkbox-pay-paypall').show();
        } else {
            $('#checkbox-pay-paypall').hide();
        }
        if ($('.terms-creditcard-fromcount').hasClass('open')) {
            $('#checkbox-pay-fromcount').show();
        } else {
            $('#checkbox-pay-fromcount').hide();
        }
        if ($('.terms-creditcard-kash').hasClass('open')) {
            $('#checkbox-pay-kash').show();
        } else {
            $('#checkbox-pay-kash').hide();
        }

        if ($('.terms-creditcard-paypall').hasClass('open')) {
            $('.paypall-link-inp').attr('required', true);
        } else {
            $('.paypall-link-inp').attr('required', false);
        }
        if ($('.terms-creditcard-fromcount').hasClass('open')) {
            $('#checkbox-pay-fromcount input').attr('required', true);
        } else {
            $('#checkbox-pay-fromcount input').attr('required', false);
        }

    });


    $('.alphaonly').bind('keyup blur', function () {
        var node = $(this);
        node.val(node.val().replace(/[^a-z\s]/gi, ''));
    }
    );
    // $(".validdate").datepicker();

    $('#val-label-airport').click(function () {
        $(this).toggleClass('activeLabel');
        $('#validation-form').validator('update');
    });


    $('#saveBusValidator').click(function () {
        $('#first-validation-form').on('submit', function (e) {
            window.setTimeout(function () {
                var errors = $('#first-validation-form .has-error')
                if (errors.length) {
                    $('html, body').animate({ scrollTop: errors.offset().top - 150 }, 500);
                }
            }, 0);
        });

        $('#validation-form').validator('update');
    });

    $(document).on('click', '.edit-validate', function () {
        // window.setTimeout(function () {
        //     var errors = $( '.edited-validation-form .has-error')
        //     if (errors.length) {
        //         $('html, body').animate({ scrollTop: errors.offset().top - 150 }, 500);
        //     }
        // }, 0);
        if ($(this)) {
            $('.edited-validation-form').validator('update');
        }


    });

    $('#saveTransferBtn').click(function () {

        if (!$('.label-add-transfer').hasClass('activeLabel')) {
            $('.offers-wrapper-single input').attr('required', true);
            alert('izaberite minimum jedan transfer');
            $('#validation-form').on('submit', function (e) {
                window.setTimeout(function () {
                    var errors = $('#validation-form .has-error')
                    if (errors.length) {
                        $('html, body').animate({ scrollTop: errors.offset().top - 50 }, 500);
                    }
                }, 0);
            });
            return false
        } else {
            $('.offers-wrapper-single input').attr('required', false);
        }
        $('#validation-form').validator('update');
    });

    $('#val-label-airport').click(function () {
        if ($(this).hasClass('activeLabel')) {
            $('#airportchecking input').attr('required', true);
        } else {
            $('#airportchecking input').attr('required', false);
            $('#airportchecking input').attr('value', '');
        }
        $('#validation-form').validator('update');
    });

    $('#val-label-sight').click(function () {
        $(this).toggleClass('activeLabel');
    });
    $('#val-label-sight').click(function () {
        if ($(this).hasClass('activeLabel')) {
            $('#sightchecking input').attr('required', true);
        } else {
            $('#sightchecking input').attr('required', false);
            $('#sightchecking input').attr('value', '');
        }
        $('#validation-form').validator('update');
    });

    $('#val-label-pausal').click(function () {
        $(this).toggleClass('activeLabel');
    });
    $('#val-label-pausal').click(function () {
        if ($(this).hasClass('activeLabel')) {
            $('#pausalchecking input').attr('required', true);
        } else {
            $('#pausalchecking input').attr('required', false);
            $('#pausalchecking input').attr('value', '');
        }
        $('#validation-form').validator('update');
    });

    $('#val-label-perhour').click(function () {
        $(this).toggleClass('activeLabel');
    });
    $('#val-label-perhour').click(function () {
        if ($(this).hasClass('activeLabel')) {
            $('#perhourchecking input').attr('required', true);
        } else {
            $('#perhourchecking input').attr('required', false);
            $('#perhourchecking input').attr('value', '');
        }
        $('#validation-form').validator('update');
    });

    $('.goal').click(function () {
        $('.goal').removeClass("active");
        $(this).addClass("active");

    });

    $(function () {

        $('#selectAero').on('change', function () {
            var selected = parseInt($(this).find('option:selected').val());
            if (selected === 2) {
                $('#from-switch').show();
                $('#to-switch').hide();
                $('#by-hour,#sight-switch,#paus-switch').hide();
                $('.buses-checkbox').addClass('optionIsSelect');
                $('#non-switch-div').hide();
                $('#non-aero-choose').hide();
                $('.aero-bus-checked').removeClass('optionIsSelect');
                $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').addClass('optionSelect');

                $('.home-you-need form input:not(.from-airport-search, [type=submit]), .home-you-need form select:not(#selectAero)').each(function (i, el) {
                    $(el).attr('disabled', 'disabled');
                });
                $('.from-airport-search').removeAttr('disabled');
            }
            if (selected === 3) {
                $('#to-switch').show();
                $('#from-switch').hide();
                $('#by-hour,#sight-switch,#paus-switch').hide();
                $('.buses-checkbox').addClass('optionIsSelect');
                $('#non-switch-div').hide();
                $('#non-aero-choose').hide();
                $('.aero-bus-checked').removeClass('optionIsSelect');
                $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').addClass('optionSelect');

                $('.home-you-need form input:not(.from-city-search, [type=submit]), .home-you-need form select:not(#selectAero)').each(function (i, el) {
                    $(el).attr('disabled', 'disabled');
                });
                $('.from-city-search').removeAttr('disabled');
            }
            if (selected === 1) {
                $('#to-switch').hide();
                $('#from-switch').hide();
                $('#by-hour,#sight-switch,#paus-switch').hide();
                $('.buses-checkbox').addClass('optionIsSelect');
                $('#non-switch-div').show();
                $('#non-aero-choose').hide();
                $('.aero-bus-checked').addClass('optionIsSelect');
                $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').removeClass('optionSelect');
            }
        });

    });


    $('#airport-label').click(function () {
        $('#non-aero-choose').show();
        $('#to-switch').hide();
        $('#from-switch').hide();
        $('#selectAero').val("1");
        $('#non-switch-div').hide();
        $('.buses-checkbox').addClass('optionIsSelect');
        $('.aero-bus-checked').removeClass('optionIsSelect');

        $('#sight-switch').hide();
        $('#paus-switch').hide();

        $('#selectAero').removeAttr('disabled');
        $('#pausal-select').attr('disabled', 'disabled');
        $('#sightseeing-select').attr('disabled', 'disabled');

    });
    $('input[type="radio"]').click(function () {
        if ($(this).attr('id') == 'transfer-to') {
            $('#to-switch').show();
            $('#to-switch').hide();
            $('#from-switch').hide();
            $('#selectAero').val("1");
            $('.buses-checkbox').removeClass('optionIsSelect');
            $('#non-switch-div').hide();
            $('#non-aero-choose').hide();
            $('.aero-bus-checked').addClass('optionIsSelect');
            $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').removeClass('optionSelect');

        }

        else {
            $('#to-switch').hide();
        }

    });
    $('input[type="radio"]').click(function () {
        if ($(this).attr('id') == 'transfer-max30km') {
            $('#by-hour').show();
            $('#to-switch').hide();
            $('#from-switch').hide();
            $('#selectAero').val("1");
            $('.buses-checkbox').removeClass('optionIsSelect');
            $('#non-switch-div').hide();
            $('#non-aero-choose').hide();
            $('.aero-bus-checked').addClass('optionIsSelect');
            $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').removeClass('optionSelect');
        }

        else {
            $('#by-hour').hide();
        }

    });

    $('#sightseeing-label').click(function () {
        $('#sight-switch').show();
        $('#to-switch').hide();
        $('#from-switch').hide();
        $('#selectAero').val("1");
        $('.buses-checkbox').removeClass('optionIsSelect');
        $('#pausal-label').addClass('optionIsSelect');
        $('#non-switch-div').hide();
        $('#non-aero-choose').hide();
        $('.aero-bus-checked').addClass('optionIsSelect');
        $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').removeClass('optionSelect');

        $('.home-you-need form input:not(.sightseeing-search, [type=submit]), .home-you-need form select:not(#Sightseeing)').each(function (i, el) {
            $(el).attr('disabled', 'disabled');
        });

        $('#Sightseeing').removeAttr('disabled');
        $('.sightseeing-search').removeAttr('disabled');
        $('#sightseeing-select').removeAttr('disabled');

        $('#selectAero').attr('disabled', 'disabled');
        $('#pausal-select').attr('disabled', 'disabled');

        $('#paus-switch').hide();
        $('#to-switch').hide();

    });
    $('#pausal-label').click(function () {
        $('#paus-switch').show();
        $('#to-switch').hide();
        $('#from-switch').hide();
        $('#selectAero').val("1");
        $('.buses-checkbox').removeClass('optionIsSelect');
        $('#sightseeing-label').addClass('optionIsSelect');
        $('#non-switch-div').hide();
        $('#non-aero-choose').hide();
        $('.aero-bus-checked').addClass('optionIsSelect');
        $('#selectAero .option').parent().parent().parent().find('.aero-bus-checked').removeClass('optionSelect');

        $('.home-you-need form input:not(.pausal-search, [type=submit]), .home-you-need form select:not(#pausal-select)').each(function (i, el) {
            $(el).attr('disabled', 'disabled');
        });

        $('.from-airport-search, .from-city-search').attr('disabled', 'disabled');
        $('#selectAero').attr('disabled', 'disabled');
        $('#sightseeing-select').attr('disabled', 'disabled');
        $('.pausal-search').removeAttr('disabled');
        $('#pausal-select').removeAttr('disabled');
        $('#bus-paush').removeAttr('disabled');

        $('#sight-switch').hide();
        $('#to-switch').hide();

    });

    $(document).ready(function () {
        $('input[type="checkbox"]').click(function () {
            if ($(this).attr('id') == 'airport_check') {
                $('#airportchecking').toggleClass('hide-box');
            }

        });
    });

    $(document).ready(function () {
        $('input[type="checkbox"]').click(function () {
            if ($(this).attr('id') == 'sight_check') {
                $('#sightchecking').toggleClass('hide-box');
            }
        });
    });
    $(document).ready(function () {
        $('input[type="checkbox"]').click(function () {
            if ($(this).attr('id') == 'pausal_check') {
                $('#pausalchecking').toggleClass('hide-box');
            }

        });
    });
    $(document).ready(function () {
        $('input[type="checkbox"]').click(function () {
            if ($(this).attr('id') == 'perhour_check') {
                $('#perhourchecking').toggleClass('hide-box');
            }

        });
    });

    $('.show-filters').click(function () {
        $('.form-filters').toggleClass("open-form-filters");

    });

    /***************input plus minus*****************/


    /****************hamburger first***************/
    $(function () {
        $('.collapsed-second').on('click', function () {
            $('.profile-sidebar').toggleClass('open').slideToggle(500);
        })

        jQuery(window).on('resize', function () {
            if (!jQuery(".collapsed-second").is(":visible") && !$('.profile-sidebar').is(':visible')) {
                $('.profile-sidebar').css({ 'display': '' });
            }
        });
    });

    /****************uploading second***************/
    $(function () {
        $('.collapsed-admin').on('click', function () {
            $('.list-group').toggleClass('open').slideToggle(500);
        })
        jQuery(window).on('resize', function () {
            if (!jQuery(".collapsed-admin").is(":visible") && !$('.list-group').is(':visible')) {
                $('.list-group').css({ 'display': '' });
            }
        });
    });

    /****************hamburger third***************/
    $(function () {
        $('.collapsed-third').on('click', function () {
            $('.main-navigation-third').toggleClass('open').slideToggle(500);
        })
        jQuery(window).on('resize', function () {
            if (!jQuery(".collapsed-third").is(":visible") && !$('.main-navigation-third').is(':visible')) {
                $('.main-navigation-third').css({ 'display': '' });
            }
        });
    });
    /****************hamburger four***************/
    $(function () {
        $('.collapsed-four').on('click', function () {
            $('.top-navigation-edit').slideToggle();
        })
        jQuery(window).on('resize', function () {
            if (!jQuery(".collapsed-four").is(":visible") && !$('.top-navigation-edit').is(':visible')) {
                $('.top-navigation-edit').css({ 'display': '' });
            }
        });
    });


    /******************remove img upload button offer**************/

    $(function () {
        $('#close-img-setings').on('click', function () {
            $('.setings-upload-img-div').hide();
        })
    });

    /******************remove img upload button offer**************/

    $(function () {
        $('#close-img').on('click', function () {
            $('.uploaded-img').hide();
            $('.image-up-div').show();
        })
    });


    /***********modal*******************/
    $(function () {
        if ($("#myModal").length) {

            $(".admin-main").addClass('admin-main-modlal');

        }
    });

    /****************TABS**************/
    $('ul.tabs').each(function () {
        // For each set of tabs, we want to keep track of
        // which tab is active and its associated content
        var $active, $content, $links = $(this).find('a');

        // If the location.hash matches one of the links, use that as the active tab.
        // If no match is found, use the first link as the initial active tab.
        $active = $($links.filter('[href="' + location.hash + '"]')[0] || $links[0]);
        $active.addClass('active');

        $content = $($active[0].hash);

        // Hide the remaining content
        $links.not($active).each(function () {
            $(this.hash).hide();
        });

        // Bind the click event handler
        $(this).on('click', 'a', function (e) {
            // Make the old tab inactive.
            $active.removeClass('active');
            $content.hide();

            // Update the variables with the new link and content
            $active = $(this);
            $content = $(this.hash);

            // Make the tab active.
            $active.addClass('active');
            $content.show();
            // Prevent the anchor's default click action
            e.preventDefault();
        });
    });


    /*************switch images on allbuses************/

    $(document).ready(function () {
        $('.img-swtich-buses').click(function () {
            var idimg = $(this).attr('class');
            var srcimg = $(this).attr('src');
            $(".img-swtich-buses-big").attr('src', srcimg);
        });
    });


    /*********************filter button*******************/
    $(function () {
        $('.toggle-filter').on('click', function () {
            $('.form-all-checkboxes').toggleClass('open').slideToggle(500);
        })

        jQuery(window).on('resize', function () {
            if (!jQuery(".toggle-filter").is(":visible") && !$('.form-all-checkboxes').is(':visible')) {
                $('.form-all-checkboxes').css({ 'display': '' });
            }
        });
    });

    /***********************blockquote toggle user auth*******************/
    $(document).ready(function () {
        // Configure/customize these variables.
        var showChar = 255;  // How many characters are shown by default
        var ellipsestext = "...";
        var moretext = "Još";
        var lesstext = "Manje";


        $('.more').each(function () {
            var content = $(this).html();

            if (content.length > showChar) {

                var c = content.substr(0, showChar);
                var h = content.substr(showChar, content.length - showChar);

                var html = c + '<span class="moreellipses">' + ellipsestext + '&nbsp;</span><span class="morecontent"><span>' + h + '</span>&nbsp;&nbsp;<div class="more-text-div"><a href="" class="morelink">' + moretext + '</a></div></span>';

                $(this).html(html);
            }

        });

        $(".morelink").click(function () {
            if ($(this).hasClass("less")) {
                $(this).removeClass("less");
                $(this).html(moretext);
            } else {
                $(this).addClass("less");
                $(this).html(lesstext);
            }
            $(this).parent().prev().toggle();
            $(this).prev().toggle();
            return false;
        });
        $(".morelink").click(function () {
            $('.moreellipses').addClass('more-elip');

            if ($('.moreellipses').hasClass('open-elip')) {
                $('.moreellipses').removeClass('open-elip');
            }
        });
    });

    /*--------------------------------------------------------------
    # Slick Slider
    --------------------------------------------------------------*/

    var slider = '.sliderImages';
    if ($(slider).length) {
        $(slider).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplay: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }


    var slider = '.sliderImages2';
    if ($(slider).length) {
        $(slider).slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplay: true,
            arrows: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 1
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
    /*--------------------------------------------------------------
       #  Slider range
       --------------------------------------------------------------*/
    if ($('#slider-range').length > 0) {
        $(function () {
            $("#slider-range").slider({
                range: true,
                min: 0,
                max: 100,
                values: [0, 100],
                slide: function (event, ui) {
                    $("#amount").val("" + ui.values[0] + " - " + ui.values[1] + " dana");
                }
            });

            $("#amount").val("" + $("#slider-range").slider("values", 0) +
                " - " + $("#slider-range").slider("values", 1) + " dana");
        });
    }

    /*--------------------------------------------------------------
  # Singup tabs
  --------------------------------------------------------------*/
    $(function () {
        if ($("#singup-tabs").length) {
            $("#singup-tabs").tabs({
                classes: {
                    "ui-tabs": "",
                    "ui-tabs-tab": "",
                    "ui-tabs-nav": "",
                    "ui-tabs-panel": ""
                },
                hide: {
                    effect: 'fade',
                    duration: 300
                },
                show: {
                    effect: 'fade',
                    duration: 300
                }
            });
        }
    });

});



/****************uploading image offer***************/
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $('#img-choose')
                .attr('src', e.target.result);
        };


        reader.readAsDataURL(input.files[0]);
        $('.image-up-div').hide();
        $('.uploaded-img').show();
    }
}

/****************uploading image setings***************/
function readURLset(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {

            $('#img-change')
                .attr('src', e.target.result)
        };


        reader.readAsDataURL(input.files[0]);
        $('.setings-upload-img-div').show();
    }
}

// this function will run everytime the on/off switch changes position
function doSomething(switchElem) {
    // if no switch element was passed bail out
    if (switchElem == null) {
        return;
    }
}

$(".addBusRow1").click(function () {
    $(".bausalAddRemove:first").clone().appendTo("#airportchecking");
    $('#validation-form').validator('update');
});

$(".addBusRow2").click(function () {
    $(".sightAddRemove:first").clone().appendTo("#sightchecking");
    $('#validation-form').validator('update');
});

$(".addBusRow3").click(function () {
    $(".sightAddRemove:first").clone().appendTo("#pausalchecking");
    $('#validation-form').validator('update');
});

$(".addBusRow4").click(function () {
    $(".perhourAddRemove:first").clone().appendTo("#perhourchecking");
    $('#validation-form').validator('update');
});


$(document).on('click', '.removeRow', function () {
    $(this).parent().remove();
});

(function ($) {
    $.fn.countTo = function (options) {
        options = options || {};

        return $(this).each(function () {
            // set options for current element
            var settings = $.extend({}, $.fn.countTo.defaults, {
                from: $(this).data('from'),
                to: $(this).data('to'),
                speed: $(this).data('speed'),
                refreshInterval: $(this).data('refresh-interval'),
                decimals: $(this).data('decimals')
            }, options);

            // how many times to update the value, and how much to increment the value on each update
            var loops = Math.ceil(settings.speed / settings.refreshInterval),
                increment = (settings.to - settings.from) / loops;

            // references & variables that will change with each update
            var self = this,
                $self = $(this),
                loopCount = 0,
                value = settings.from,
                data = $self.data('countTo') || {};

            $self.data('countTo', data);

            // if an existing interval can be found, clear it first
            if (data.interval) {
                clearInterval(data.interval);
            }
            data.interval = setInterval(updateTimer, settings.refreshInterval);

            // initialize the element with the starting value
            render(value);

            function updateTimer() {
                value += increment;
                loopCount++;

                render(value);

                if (typeof (settings.onUpdate) == 'function') {
                    settings.onUpdate.call(self, value);
                }

                if (loopCount >= loops) {
                    // remove the interval
                    $self.removeData('countTo');
                    clearInterval(data.interval);
                    value = settings.to;

                    if (typeof (settings.onComplete) == 'function') {
                        settings.onComplete.call(self, value);
                    }
                }
            }

            function render(value) {
                var formattedValue = settings.formatter.call(self, value, settings);
                $self.html(formattedValue);
            }
        });
    };

    $.fn.countTo.defaults = {
        from: 0,               // the number the element should start at
        to: 0,                 // the number the element should end at
        speed: 1000,           // how long it should take to count between the target numbers
        refreshInterval: 100,  // how often the element should be updated
        decimals: 0,           // the number of decimal places to show
        formatter: formatter,  // handler for formatting the value before rendering
        onUpdate: null,        // callback method for every time the element is updated
        onComplete: null       // callback method for when the element finishes updating
    };

    function formatter(value, settings) {
        return value.toFixed(settings.decimals);
    }
}(jQuery));

jQuery(function ($) {
    // custom formatting example
    $('.count-number').data('countToOptions', {
        formatter: function (value, options) {
            return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
        }
    });

    $('#test-btn').on('click', function (e) {
        e.preventDefault();

        $(this).parents('#test-form').validator('valdiate');

        alert('radi');
    })

    // start all the timers
    $('.timer').each(count);

    function count(options) {
        var $this = $(this);
        options = $.extend({}, options || {}, $this.data('countToOptions') || {});
        $this.countTo(options);
    }
});

if ($('#slider-bus-tour-new').length > 0) {
    $('#slider-bus-tour-new').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        prevArrow: "<i class=\"glyphicon glyphicon-menu-left\"></i>",
        nextArrow: "<i class=\"glyphicon glyphicon-menu-right\"></i>",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}
if ($('#slider-bus-tour-new2').length > 0) {
    $('#slider-bus-tour-new2').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: "<i class=\"glyphicon glyphicon-menu-left\"></i>",
        nextArrow: "<i class=\"glyphicon glyphicon-menu-right\"></i>",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}
if ($('#slider-bus-tour-new3').length > 0) {
    $('#slider-bus-tour-new3').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: "<i class=\"glyphicon glyphicon-menu-left\"></i>",
        nextArrow: "<i class=\"glyphicon glyphicon-menu-right\"></i>",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });
}
(function ($) {
    var inputs = $('.input');
    var inp = inputs.find('input');

    inp.on('input', function () {
        var v = $(this).val();
        if (v.length > 5) {
            $(this).val('12:00');
        }
    }).on('focusout', function () {
        var input = $(this);
        var v = input.val();

        if (v.substring(0, 1) === '0' && v.substring(1, 2) === '0') {
            input.val(v.substring(1, v.length))
        }
    })

    var inputs = $('.input');
    var inp = inputs.find('input');

    inp.on('input', function () {
        var v = $(this).val();
        if (v.length > 5) {
            $(this).val('12:00');
        }
    }).on('focusout', function () {
        var input = $(this);
        var v = input.val();

        if (v.substring(0, 1) === '0' && v.substring(1, 2) === '0') {
            input.val(v.substring(1, v.length))
        }
    })

    inputs.find('span').on('click', function () {
        var inp = $(this).siblings('input');
        var current = inp.val();
        current = current.split(':');

        var hrs = current[0];
        var min = current[1];

        if ($(this).hasClass('minus')) {

            if (inp.val().length > 5 || inp.val().indexOf(':') === -1) {
                inp.val('12:00');
                return false;
            }

            if (min === '0') {
                if (hrs === '0') {
                    inp.val('23:45');
                } else {
                    hrs--;
                    inp.val(hrs + ':45');
                }
            } else {
                min = min - 15;

                if (min.toString().length === 1) {
                    min = '0' + min;
                }

                if (parseInt(min) < 0) {
                    hrs--;
                    min = 60 - (- min);
                }

                if (parseInt(hrs) < 0) {
                    hrs = '23';
                }

                inp.val(hrs + ':' + min);
            }
        } else {

            if (inp.val().length > 5 || inp.val().indexOf(':') === -1) {
                inp.val('12:00');
                return false;
            }

            if (min === '45') {
                if (hrs === '23') {
                    inp.val('0:00')
                } else {
                    hrs++;
                    inp.val(hrs + ':00');
                }
            } else {
                min = parseInt(min) + 15;

                if (min.toString().length === 1) {
                    min = '0' + min;
                }

                if (parseInt(min) > 60) {
                    hrs++;
                    min = '0' + (min - 60);
                }

                if (parseInt(hrs) > 23) {
                    hrs = '00';
                }

                inp.val(hrs + ':' + min);
            }
        }

    })

    /*************************animations****************************/
    function isScrolledIntoView(elem) {
        var $elem = $(elem);
        var $window = $(window);

        var docViewTop = $window.scrollTop() + 400;
        var docViewBottom = docViewTop + $window.height();

        var elemTop = $elem.offset().top;
        var elemBottom = elemTop + $elem.height();

        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    // $(window).scroll(function(){
    //     $('.wpb_wrapper').each(function(){
    //         if(isScrolledIntoView($(this))){
    //             $(this).addClass('viewportElement');
    //         }
    //     });
    // });



    $(window).bind("resize", function () {
        if ($(this).width() < 991) {
            $('.icon-info2 .tool-text').hide();
            $('.icons-market-wrap .icon-info2:first').addClass('info-trans-first');
            $('.icons-market-wrap .icon-info2:eq(1)').addClass('info-trans-second');
            $('.icons-market-wrap .icon-info2:eq(2)').addClass('info-trans-third');
            $('.trd-people .icon-info2').addClass('trd-people-info');
        } else {
            $('.icon-info2 .tool-text').show();
            $('.icons-market-wrap .icon-info2:first').removeClass('info-trans-first');
            $('.icons-market-wrap .icon-info2:eq(1)').removeClass('info-trans-second');
            $('.icons-market-wrap .icon-info2:eq(2)').removeClass('info-trans-third');
            $('.trd-people .icon-info2').removeClass('trd-people-info');
        }
        if ($(this).width() > 991) {
            $('.trans-one-dir-mobiltip').hide();
            $('.icons-market-wrap .icon-info2:first').removeClass('info-trans-first');
            $('.icons-market-wrap .icon-info2:eq(1)').removeClass('info-trans-second');
            $('.icons-market-wrap .icon-info2:eq(2)').removeClass('info-trans-third');
            $('.trd-people .icon-info2').removeClass('trd-people-info');
        }
    }).trigger('resize');

    $('.info-trans-first').mouseenter(function () {
        $('.trans-one-dir-mobiltip').fadeIn('slow');
    });
    $('.info-trans-first').mouseleave(function () {
        $('.trans-one-dir-mobiltip').fadeOut('slow');
    });

    $('.info-trans-second').mouseenter(function () {
        $('.trans-two-dir-mobiltip').fadeIn('slow');
    });
    $('.info-trans-second').mouseleave(function () {
        $('.trans-two-dir-mobiltip').fadeOut('slow');
    });

    $('.info-trans-third').mouseenter(function () {
        $('.trans-three-dir-mobiltip').fadeIn('slow');
    });
    $('.info-trans-third').mouseleave(function () {
        $('.trans-three-dir-mobiltip').fadeOut('slow');
    });

    $('.trd-people-info').mouseenter(function () {
        $('.trans-numberPass-mobiltip').fadeIn();
    });
    $('.trd-people-info').mouseleave(function () {
        $('.trans-numberPass-mobiltip').fadeOut();
    });

    $('.changesAndChancel').click(function () {
        $('.changesSlideDiv').slideToggle();
    });






    $(window).scroll(function () {
        $('.icons-market-wrap img').each(function () {
            if (isScrolledIntoView($(this))) {
                $(this).addClass('viewportElement');
            }
        });
    });
    /* This is basic - uses default settings */

    // $("a#single_image").fancybox();
    //
    // /* Using custom settings */
    //
    // $("a#inline").fancybox({
    //     'hideOnContentClick': true
    // });

    /* Apply fancybox to multiple items */
    $(document).ready(function () {
        var collapsableBtn = $('.js-collapsable-btn'),
            collapsableMarker = $('.js-collapsable-marker'),
            collapsable = $('.js-collapsable');

        if (collapsable.length > 0 && collapsableBtn.length > 0 && collapsableMarker.length > 0) {
            collapsableBtn.on('click', function () {
                collapsable.toggleClass('show');
            });

            function changeScheduleTableStickiness() {
                if ($(window).scrollTop() + $(window).height() < collapsableMarker.offset().top) {
                    collapsable.removeClass('relative');
                } else {
                    collapsable.addClass('relative');
                }
            }
            changeScheduleTableStickiness();

            $(window).on('scroll', function () {
                changeScheduleTableStickiness();
            });
        }
    });
})(jQuery)